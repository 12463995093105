import { pushEventsLog } from '../redux/actions';
import store from '../redux/store';

const eventLogger = (eventName, additionalData = {}, showInConsole = false) => {
  const storeData = store.getState().storeReducer.store;
  const userData = store.getState().userReducer?.data;

  const eventData = {
    store_id: storeData?.store_id,
    phone: userData?.phone || '',
    email: userData?.email || '',
    ...additionalData,
  };

  store.dispatch(
    pushEventsLog({
      event_name: eventName,
      data: eventData,
    })
  );
};

export default eventLogger;
